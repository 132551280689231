import React, { useRef } from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import TopMenu from "./TopMenuVideo";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

interface WeAreHiringProps {
  launched: boolean;
}

const WeAreHiring: React.FC<WeAreHiringProps> = ({ launched }) => {
  const bottomRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const location = useLocation();
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);

  return (
    <>
      <TopMenu
        onScrollToBottom={scrollToBottom}
        onScrollToTop={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
        launched={launched}
      />
      <Box
        sx={{
          bgcolor: "background.default",
          color: "text.primary",
          //padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Adjusted for overall alignment
          justifyContent: "center", // Adjusted for vertical alignment
          width: "100%", // Full width
          maxWidth: "1300px", // Maximum width
          marginLeft: "auto", // Centering horizontally
          marginRight: "auto", // Centering horizontally
          padding: "0 23px 0 23px",
          //marginTop: isMobile ? -8 : -13,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left", // Adjusted for overall alignment
            justifyContent: "center", // Adjusted for vertical alignment
            width: "100%", // Full width
            //height: "300px",
          }}
        ></Box>
        <Card sx={{ width: "100%", mt: 10, marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h4" component="h1" gutterBottom>
              SoftCap is hiring Software Engineering students!
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              About us
            </Typography>
            <Typography variant="body1" paragraph>
              SoftCap AS is a startup founded in 2023, based in Tromsø. We are
              dedicated to building world-class software for the professional
              services industry, with a focus on investment banking.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Highlights
            </Typography>
            <Typography variant="body1" paragraph>
              In SoftCap, you will have the opportunity to immerse yourself in
              various aspects of software development. Our modern tech stack and
              rapid adoption of cloud and AI advancements allow us to stay ahead
              of the curve, continuously improving our product and development
              processes. Whether your passion lies in crafting exceptional user
              experiences, building robust backend systems, integrating AI, or
              ensuring seamless continuous integration, there’s a place for you
              here.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              What you will do
            </Typography>
            <Typography variant="body1" paragraph>
              • Design and build intuitive and engaging UX/UI for our
              applications on the web, mobile and M365 add-ins
              <br />
              • Develop and enhance backend features to ensure reliability and
              scalability
              <br />
              • Integrate AI capabilities to drive innovation and efficiency
              <br />• Contribute to continuous integration processes, helping to
              ship a brand-new product
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Who we are looking for
            </Typography>
            <Typography variant="body1" paragraph>
              • Currently enrolled in a computer science program (preferably 2nd
              year or higher)
              <br />
              • Strong interest in software development and a passion for
              learning new technologies
              <br />
              • Ability to work independently and collaboratively within a team
              <br />
              • Flexible work hours / ~20-40 hours per month
              <br />
              • Ready to start in September/October 2024
              <br />• Work location in Tromsø
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Why join SoftCap?
            </Typography>
            <Typography variant="body1" paragraph>
              • Innovative environment: work with the latest technologies in
              cloud and AI
              <br />
              • Diverse opportunities: choose from a variety of tasks that match
              your interests and skills
              <br />• Flexible hours and competitive salary: enjoy the
              flexibility to work almost any time that suits your schedule and
              receive a competitive salary
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              How to apply
            </Typography>
            <Typography variant="body1" paragraph>
              Send your resume in pdf and a brief cover mail to{" "}
              <a href="mailto:contact@softcap.no" style={{ color: "white" }}>
                contact@softcap.no
              </a>
              <br />
            </Typography>

            <Box mt={2}></Box>
          </CardContent>
        </Card>
        <div ref={bottomRef}></div>
      </Box>
    </>
  );
};

export default WeAreHiring;
