// src/AppRoutes.tsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import CRMWaitlist from "./crm/CrmWaitList";
import ProtectedRoute from "./crm/ProtectedRoute";
import VideoPlayerPage from "./pages/VideoPlayerPage";
import VideoPlayerPageUsers from "./pages/VideoPlayerPageUsers";
import WeAreHiring from "./pages/WeAreHiring";

import NotFoundPage from "./components/NotFoundPage";

import usePageTracking from "./hooks/usePageTracking";

const LandingPage = React.lazy(() => import("./pages/LandingPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const Bioteam = React.lazy(() => import("./pages/Bioteam2024x"));
//const WeAreHiring = React.lazy(() => import("./pages/Wearehiring"));

// Import other pages as needed
interface AppRoutesProps {
  launched: boolean;
}
const AppRoutes: React.FC<AppRoutesProps> = ({ launched }) => {
  usePageTracking();
  return (
    <Routes>
      <Route path="/" element={<LandingPage launched={launched} />} />

      {/*
      <Route path="/bioteam" element={<Bioteam launched={launched} />} />
      */}

      <Route path="/login365" element={<LoginPage />} />
      <Route
        path="/crm-waitlist"
        element={
          <ProtectedRoute>
            <CRMWaitlist />
          </ProtectedRoute>
        }
      />

      <Route path="/video-player" element={<VideoPlayerPage />} />
      <Route path="/video-player-users" element={<VideoPlayerPageUsers />} />

      <Route path="/hiring" element={<WeAreHiring launched={false} />} />

      <Route path="*" element={<NotFoundPage />} />

      {/* Add more routes here */}
    </Routes>
  );
};

export default AppRoutes;
