import React, { useState } from "react";
import { getStorage, ref, uploadBytes } from "firebase/storage";

const VideoUpload: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.type === "video/mp4" || file.type === "application/pdf") {
      setFile(file);
    } else {
      alert("Please select an mp4 video file or a PDF document.");
      setFile(null);
    }
  };

  const handleUpload = async () => {
    if (file) {
      const storage = getStorage();
      let storageRef;

      if (file.type === "application/pdf") {
        storageRef = ref(storage, `pdf/${file.name}`);
      } else {
        storageRef = ref(storage, `videos/${file.name}`);
      }

      try {
        await uploadBytes(storageRef, file);
        alert(
          `${
            file.type === "application/pdf" ? "PDF" : "Video"
          } uploaded successfully!`
        );
      } catch (error) {
        console.error("Upload failed:", error);
        alert("Upload failed. Please try again.");
      }
    }
  };

  return (
    <div>
      <input type="file" accept="video/mp4,.pdf" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={!file}>
        Upload File
      </button>
    </div>
  );
};

export default VideoUpload;
