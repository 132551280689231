import React, { useEffect, useState } from "react";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";

interface VideoInfo {
  url: string;
  name: string;
  size: number; // Size in bytes
}

const VideoList: React.FC = () => {
  const [videos, setVideos] = useState<VideoInfo[]>([]);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const firestore = getFirestore();

  useEffect(() => {
    const fetchVideos = async () => {
      const storage = getStorage();
      const listRef = ref(storage, "videos/");

      try {
        const result = await listAll(listRef);
        const videoInfoPromises = result.items.map(async (itemRef) => {
          const url = await getDownloadURL(ref(storage, itemRef.fullPath));
          const metadata = await getMetadata(ref(storage, itemRef.fullPath));
          return {
            url,
            name: itemRef.name,
            size: metadata.size / 1024 / 1024,
          };
        });

        const videoInfos = await Promise.all(videoInfoPromises);
        setVideos(videoInfos);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, []);

  const handleViewVideo = (videoUrl: string) => {
    navigate(`/video-player?url=${encodeURIComponent(videoUrl)}`);
  };

  const handleDistributeClick = (videoUrl: string) => {
    setSelectedVideo(videoUrl);
    setEmail("");
  };

  const handleDistributeSubmit = async () => {
    if (selectedVideo && email) {
      const videoId = selectedVideo.replace(/^\/+/, "").replace(/\//g, "-");
      const userLink = `${
        window.location.origin
      }/video-player-users?url=${encodeURIComponent(
        selectedVideo
      )}&email=${encodeURIComponent(email)}`;
      const assetDoc = doc(
        firestore,
        "AssetDistribution",
        `${email}-${videoId}`
      );

      await setDoc(assetDoc, {
        videoUrl: selectedVideo,
        email,
        timestamp: new Date().toISOString(),
        userLink,
        type: "MP4",
      });

      // Copy the userLink to the clipboard
      try {
        await navigator.clipboard.writeText(userLink);
        alert(
          "Video link has been copied to clipboard and is ready for distribution to " +
            email
        );
      } catch (err) {
        console.error("Failed to copy the link to clipboard", err);
        alert(
          "Failed to copy the link to clipboard. Please manually copy the link: " +
            userLink
        );
      }
    }
  };

  return (
    <Box sx={{ mt: "30px" }}>
      <Typography variant="h4">Video List</Typography>
      {videos.map((video, index) => (
        <Box key={index} sx={{ mb: 4 }}>
          <Typography variant="subtitle1">{video.name}</Typography>
          <Typography variant="body2">
            Size: {video.size.toFixed(2)} MB
          </Typography>
          <video width="320" height="240" controls>
            <source src={video.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={() => handleViewVideo(video.url)}
            >
              View Video as Admin
            </Button>
            <Button
              variant="contained"
              onClick={() => handleDistributeClick(video.url)}
            >
              Distribute Video
            </Button>
          </Stack>
          {selectedVideo === video.url && (
            <Stack
              direction="row"
              spacing={2}
              sx={{ mt: 2 }}
              alignItems="center"
            >
              <TextField
                variant="filled"
                label="Enter user email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="small"
              />
              <Button variant="contained" onClick={handleDistributeSubmit}>
                Submit
              </Button>
            </Stack>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default VideoList;
