import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { format } from "date-fns";

interface Asset {
  type: string;
  email: string;
  playCount: number;
  timestamp: string;
  videoUrl: string;
  documentUrl: string;
  userLink: string;
  activated: string[];
}

const AssetDistributionList: React.FC = () => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const firestore = getFirestore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchAssets = async () => {
      const querySnapshot = await getDocs(
        collection(firestore, "AssetDistribution")
      );
      const assetsData: Asset[] = [];
      querySnapshot.forEach((doc) => {
        assetsData.push(doc.data() as Asset);
      });
      setAssets(assetsData);
    };

    fetchAssets();
  }, [firestore]);

  const extractFileName = (url: string): string => {
    const decodedUrl = decodeURIComponent(url);
    const match = decodedUrl.match(/[^\/]+(?=\?)/);
    return match ? match[0] : "Unknown file";
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const renderCard = (asset: Asset) => (
    <Grid item xs={12} sm={6} md={4} key={asset.email}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="body2">
            File name: {extractFileName(asset.videoUrl)}
          </Typography>
          <Typography variant="body2">Media Type: {asset.type}</Typography>
          <Typography variant="body2">Email: {asset.email}</Typography>
          <Typography variant="body2">Play Count: {asset.playCount}</Typography>
          <Typography variant="body2">
            Timestamp: {format(new Date(asset.timestamp), "MMM dd yyyy, HH:mm")}
          </Typography>
          <Typography variant="body2">
            Video URL:{" "}
            <IconButton
              size="small"
              onClick={() => copyToClipboard(asset.videoUrl)}
              sx={{
                color: "text.secondary", // Sets the icon color
                border: "1px solid", // Adds an outline
                borderColor: "text.secondary", // Sets the outline color
                borderRadius: "6px", // Makes the outline rounded
                "&:hover": {
                  color: "#fff", // Changes the color on hover
                  borderColor: "#fff", // Changes the outline color on hover
                },
              }}
            >
              <ContentCopyOutlinedIcon />
            </IconButton>
          </Typography>
          <Typography variant="body2">
            User Link:{" "}
            <IconButton
              size="small"
              onClick={() => copyToClipboard(asset.userLink)}
              sx={{
                color: "text.secondary", // Sets the icon color
                border: "1px solid", // Adds an outline
                borderColor: "text.secondary", // Sets the outline color
                borderRadius: "6px", // Makes the outline rounded
                "&:hover": {
                  color: "#fff", // Changes the color on hover
                  borderColor: "#fff", // Changes the outline color on hover
                },
              }}
            >
              <ContentCopyOutlinedIcon />
            </IconButton>
          </Typography>
          <Typography variant="body2">
            Status:{" "}
            {asset.activated?.length > 0 ? "Activated" : "Not Activated"}
          </Typography>
          <Typography variant="body2">
            Activations: {asset.activated?.length ?? "N/A"}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <>
      <Box sx={{ mt: "30px", p: "3px" }}>
        <Typography variant="h4">Asset Distribution List</Typography>
      </Box>
      {isMobile ? (
        <Grid container spacing={2} sx={{ p: 2 }}>
          {assets.map((asset) => renderCard(asset))}
        </Grid>
      ) : (
        <Paper sx={{ overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Filename</TableCell>
                <TableCell>Media Type</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Play Count</TableCell>
                <TableCell>Timestamp</TableCell>
                <TableCell>Admin URL</TableCell>
                <TableCell>User link sent</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Activations</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assets.map((asset, index) => (
                <TableRow key={index}>
                  <TableCell>{extractFileName(asset.videoUrl)}</TableCell>
                  <TableCell>{asset.type}</TableCell>
                  <TableCell>{asset.email}</TableCell>
                  <TableCell>{asset.playCount}</TableCell>
                  <TableCell>
                    {format(new Date(asset.timestamp), "MMM dd yyyy, HH:mm")}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => copyToClipboard(asset.videoUrl)}
                      sx={{
                        color: "text.secondary", // Sets the icon color
                        border: "1px solid", // Adds an outline
                        borderColor: "text.secondary", // Sets the outline color
                        borderRadius: "6px", // Makes the outline rounded
                        "&:hover": {
                          color: "#fff", // Changes the color on hover
                          borderColor: "#fff", // Changes the outline color on hover
                        },
                      }}
                    >
                      <ContentCopyOutlinedIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => copyToClipboard(asset.userLink)}
                      sx={{
                        color: "text.secondary", // Sets the icon color
                        border: "1px solid", // Adds an outline
                        borderColor: "text.secondary", // Sets the outline color
                        borderRadius: "6px", // Makes the outline rounded
                        "&:hover": {
                          color: "#fff", // Changes the color on hover
                          borderColor: "#fff", // Changes the outline color on hover
                        },
                      }}
                    >
                      <ContentCopyOutlinedIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton sx={{ border: "1px #fff" }}>
                      <Tooltip
                        title={
                          asset.activated?.length > 0
                            ? "Activated"
                            : "Not Activated"
                        }
                      >
                        {asset.activated?.length > 0 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <UnpublishedIcon color="warning" />
                        )}
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {asset.activated?.length > 0
                      ? asset.activated.length
                      : "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </>
  );
};

export default AssetDistributionList;
