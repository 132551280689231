import React, { Suspense, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./theme";
import AppRoutes from "./AppRoutes";
import GDPRNotice from "./components/GDRPNotice";
import Footer from "./components/Footer";
const Analyitcs = React.lazy(() => import("./components/Analytics"));

const App: React.FC = () => {
  const [launch, setLaunch] = useState(true);

  // Set the initial state based on the local storage value
  const [showNotice, setShowNotice] = useState(
    localStorage.getItem("gdprNoticeSeen") !== "true"
  );

  const bottomRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleOpenNotice = () => {
    setShowNotice(true);
  };

  const handleCloseNotice = () => {
    localStorage.setItem("gdprNoticeSeen", "true");
    setShowNotice(false);
  };
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense>
          <Analyitcs />
        </Suspense>
        <Suspense>
          <AppRoutes launched={launch} />
          <div>
            {showNotice && <GDPRNotice onClose={handleCloseNotice} />}
            {/* Rest of your app */}
          </div>
          {launch && (
            <>
              <div ref={bottomRef} />
              <Footer launched={launch} onOpenNotice={handleOpenNotice} />
            </>
          )}
        </Suspense>
      </ThemeProvider>
    </Router>
  );
};

export default App;
