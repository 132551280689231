import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Container,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  increment,
} from "firebase/firestore";
import TopMenu from "../components/TopMenu";
import TopMenuVideo from "./TopMenuVideo";

const VideoPlayerPageUsers: React.FC = () => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const firestore = getFirestore();
  const accessRecordedRef = useRef(false);

  const videoUrl = new URLSearchParams(location.search).get("url");
  const userEmail = new URLSearchParams(location.search).get("email");
  const videoName = videoUrl ? videoUrl.split("/").pop() : "";
  const [playCount, setPlayCount] = useState(0);
  const [lastPlayTime, setLastPlayTime] = useState<number>(0);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const launched = true;

  useEffect(() => {
    // Fetch existing play count from Firestore
    const fetchPlayCount = async () => {
      if (videoName) {
        const docRef = doc(firestore, "videos", videoName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPlayCount(docSnap.data().playCount || 0);
        } else {
          // If the document does not exist, initialize it
          await setDoc(docRef, { playCount: 0 });
        }
      }
    };

    fetchPlayCount();
  }, [firestore, videoName]);

  useEffect(() => {
    const verifyAndRecordAccess = async () => {
      if (videoUrl && userEmail) {
        const videoId = videoUrl.replace(/^\/+/, "").replace(/\//g, "-");

        // Record in VideoViews
        const viewDocRef = doc(
          firestore,
          "VideoViews",
          `${userEmail}-${videoId}-${new Date().toISOString()}`
        );
        await setDoc(viewDocRef, {
          videoUrl,
          userEmail,
          timestamp: new Date().toISOString(),
        });

        // Verify and record access in AssetDistribution
        const assetDocRef = doc(
          firestore,
          "AssetDistribution",
          `${userEmail}-${videoId}`
        );
        const docSnap = await getDoc(assetDocRef);
        setIsLoading(false);
        if (docSnap.exists()) {
          setIsAuthorized(true);

          await updateDoc(assetDocRef, {
            activated: arrayUnion(new Date().toISOString()),
          });
        } else {
          console.log("Unauthorized access attempt.");
        }
      }
    };

    verifyAndRecordAccess();
  }, [videoUrl, userEmail, firestore]);

  if (!isAuthorized) {
    return <div></div>;
  }

  const handlePlay = async () => {
    if (!videoUrl || !userEmail) {
      return; // Exit the function if videoUrl or userEmail is null
    }

    const currentTime = new Date().getTime();
    const MINIMUM_PAUSE_DURATION = 30 * 1000; // 30 seconds in milliseconds
    if (
      videoName &&
      (lastPlayTime === 0 ||
        currentTime - lastPlayTime > MINIMUM_PAUSE_DURATION)
    ) {
      const newCount = playCount + 1;
      setPlayCount(newCount);
      setLastPlayTime(currentTime);

      const videoId = videoUrl.replace(/^\/+/, "").replace(/\//g, "-");
      const assetDocRef = doc(
        firestore,
        "AssetDistribution",
        `${userEmail}-${videoId}`
      );
      const docRef = doc(firestore, "videos", videoName);

      // Update play count in AssetDistribution and videos
      await updateDoc(assetDocRef, { playCount: increment(1) });
      await updateDoc(docRef, { playCount: newCount });
    }
  };

  return (
    <>
      <TopMenuVideo
        onScrollToBottom={scrollToBottom}
        onScrollToTop={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
        launched={launched}
      />

      <Container
        maxWidth="md"
        sx={{
          height: isMobile ? "auto" : "600px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "left",
          alignItems: "left",
          pt: "90px",
          pb: "90px",
        }}
      >
        {videoName && (
          <Typography variant="subtitle1" color="text.secondary">
            {/*videoName*/}
          </Typography>
        )}
        {videoUrl && (
          <video
            width={isMobile ? "100%" : "80%"}
            height={isMobile ? "auto" : "80%"}
            controls
            controlsList="nodownload"
            onPlay={handlePlay}
            style={{ maxWidth: "100%", height: "auto" }}
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            alignItems: "left",
          }}
        >
          <Typography
            variant={isMobile ? "subtitle2" : "h5"}
            sx={{ p: 2, alignItems: "center", color: "text.secondary" }}
          >
            DealMaker AI / Video Player / Press play
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default VideoPlayerPageUsers;
