import React, { useEffect, useState } from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { Box, Typography } from "@mui/material";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { firestore } from "../firebaseConfig";

const DocumentList: React.FC = () => {
  const [documents, setDocuments] = useState<string[]>([]);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [email, setEmail] = useState("");
  const firestore = getFirestore();

  useEffect(() => {
    const fetchDocuments = async () => {
      const storage = getStorage();
      const listRef = ref(storage, "pdf/");

      try {
        const result = await listAll(listRef);
        const urlPromises = result.items.map((itemRef) =>
          getDownloadURL(ref(storage, itemRef.fullPath))
        );

        const urls = await Promise.all(urlPromises);
        setDocuments(urls);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchDocuments();
  }, []);

  const handleDistributeClick = (documentUrl: string) => {
    setSelectedDocument(documentUrl);
    setEmail("");
  };

  const handleDistributeSubmit = async () => {
    if (selectedDocument && email) {
      const documentId = selectedDocument
        .replace(/^\/+/, "")
        .replace(/\//g, "-");
      const userLink = `${
        window.location.origin
      }/document-viewer?url=${encodeURIComponent(
        selectedDocument
      )}&email=${encodeURIComponent(email)}`;
      const assetDoc = doc(
        firestore,
        "AssetDistribution",
        `${email}-${documentId}`
      );
      await setDoc(assetDoc, {
        documentUrl: selectedDocument,
        email,
        timestamp: new Date().toISOString(),
        userLink,
        type: "PDF",
      });

      alert("Document link ready for distribution to " + email);
      // Optionally, copy the userLink to the clipboard
    }
  };

  return (
    <Box sx={{ mt: "30px" }}>
      <Typography variant="h4">Document List</Typography>
      {documents.map((documentUrl, index) => (
        <div key={index}>
          <a href={documentUrl} target="_blank" rel="noopener noreferrer">
            View Document
          </a>
          <button onClick={() => handleDistributeClick(documentUrl)}>
            Distribute Document
          </button>
          {selectedDocument === documentUrl && (
            <div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter user email"
              />
              <button onClick={handleDistributeSubmit}>Submit</button>
            </div>
          )}
        </div>
      ))}
    </Box>
  );
};

export default DocumentList;
