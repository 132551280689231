import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  //Button,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { link } from "fs";
//import { SignInDialog } from "./SignInDialog";

//import { useNavigate } from "react-router-dom";

interface TopMenuProps {
  onScrollToBottom: () => void;
  onScrollToTop: () => void;
  launched: boolean;
}

const TopMenu: React.FC<TopMenuProps> = ({
  //onScrollToBottom,
  onScrollToTop,
  launched,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [scrolled, setScrolled] = useState(false);
  // const navigate = useNavigate();

  const linkToHome = () => {
    window.location.href = "/";
  };

  /*
  const handleSignInClick = () => {
    navigate("/login"); // Navigate to the login route
  };
*/
  // Function to handle scroll event
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      // Adjust this value based on when you want to change the background
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  // Adding scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: scrolled
          ? theme.palette.background.default
          : "transparent", // Change color as needed
        boxShadow: "none",
        transition: "background-color 0.3s ease", // Smooth transition for background color
      }}
    >
      <Toolbar
        style={{
          justifyContent: "center",
          width: "100%",
          maxWidth: "1300px",
          margin: "0 auto",
        }}
      >
        <Typography
          variant={isMobile ? "body1" : "h6"}
          component="div"
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={linkToHome}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: "0px",
              mt: "3px",
              mr: "3px",
            }}
          >
            <img
              src="/icon-256x256.png"
              alt="logo"
              style={{ height: isMobile ? "21px" : "27px" }}
            />
          </Box>
          <span style={{ fontWeight: 500 }}>soft</span>
          <span style={{ fontWeight: 300 }}> cap</span>
        </Typography>
        {/*<Button color="inherit">Superusers</Button> */}

        {launched && (
          <>
            {/*
            <Button
              size={isMobile ? "small" : "medium"}
              color="inherit"
              variant="outlined"
              sx={{
                mr: "6px",
                borderColor: "transparent",
                "&:hover": {
                  borderColor: "#fff",
                  variant: "outlined",
                },
              }}
              onClick={handleSignInClick}
            >
              Sign in
            </Button>
            */}
            {/* SignInDialog component */}

            {/*
            <Button
              variant="outlined"
              size={isMobile ? "small" : "medium"}
              color="inherit"
              onClick={onScrollToBottom}
              sx={{
                borderColor: "transparent",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: theme.palette.background.paper,
                  borderColor: "#fff",
                },
              }}
            >
              Waitlist
            </Button>
            */}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopMenu;
