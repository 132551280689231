// VideoPlayerPage.tsx

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

const VideoPlayerPage: React.FC = () => {
  const location = useLocation();
  const videoUrl = new URLSearchParams(location.search).get("url");
  const videoName = videoUrl ? videoUrl.split("/").pop() : "";
  const [playCount, setPlayCount] = useState(0);
  const [lastPlayTime, setLastPlayTime] = useState<number>(0);

  const firestore = getFirestore();

  useEffect(() => {
    // Fetch existing play count from Firestore
    const fetchPlayCount = async () => {
      if (videoName) {
        const docRef = doc(firestore, "videos", videoName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPlayCount(docSnap.data().playCount || 0);
        } else {
          // If the document does not exist, initialize it
          await setDoc(docRef, { playCount: 0 });
        }
      }
    };

    fetchPlayCount();
  }, [firestore, videoName]);

  const MINIMUM_PAUSE_DURATION = 30 * 1000; // 30 seconds in milliseconds

  const handlePlay = async () => {
    const currentTime = new Date().getTime();
    if (
      videoName &&
      (lastPlayTime === 0 ||
        currentTime - lastPlayTime > MINIMUM_PAUSE_DURATION)
    ) {
      const newCount = playCount + 1;
      setPlayCount(newCount);
      setLastPlayTime(currentTime);

      const docRef = doc(firestore, "videos", videoName);
      await updateDoc(docRef, { playCount: newCount });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        <Typography variant="h2">Video Player</Typography>
        {videoName && (
          <Typography variant="subtitle1" color="text.secondary">
            {videoName}
          </Typography>
        )}
        {videoUrl && (
          <video
            width="640"
            height="480"
            controls
            controlsList="nodownload"
            onPlay={handlePlay}
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        <Typography variant="body1" color="text.secondary">
          Played: {playCount} times
        </Typography>
      </div>
    </div>
  );
};

export default VideoPlayerPage;
