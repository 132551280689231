import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
//import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBy6xMpFhnuZ2_FjQuu-gxcZRUk_o_gI8M",
  authDomain: "softcapweb.firebaseapp.com",
  projectId: "softcapweb",
  storageBucket: "softcapweb.appspot.com",
  messagingSenderId: "840858102999",
  appId: "1:840858102999:web:528e3f7f59c4e458371492",
  measurementId: "G-T4JDCEC0FK",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Get a reference to the Firestore service
export const firestore = getFirestore(app);
export const auth = getAuth(app);
//export const analytics = getAnalytics(app);
