// src/theme.ts
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6", // Example primary color
    },
    secondary: {
      main: "#19857b", // Example secondary color
    },
    error: {
      main: "#ff1744", // Example error color
    },
    background: {
      default: "#0d1117",
      paper: "#12181f", // Example background color for paper elements
    },
    text: {
      primary: "#ffffff",
      secondary: "#8b949e", // Example secondary text color
    },
  },
  typography: {
    fontFamily: "Barlow, Arial",

    h2: {
      fontSize: "3.5rem", // Example style for h2
    },

    // ... add more typography styles as needed
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Example to stop uppercase transform in buttons
          // ... add more button styles as needed
        },
      },
    },
    // ... override other component styles as needed
  },
  // ... add more theme customizations as needed
});

export default theme;
