import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

// Assuming this is the interface you defined earlier
interface Email {
  id: string;
  email: string; // Add other fields as per your Firestore document structure
  code: string;
  createdAt: string; // Adjust the type as needed
}

// Define a type for your component's props
interface EmailsTableProps {
  emails: Email[];
}

const EmailsTable: React.FC<EmailsTableProps> = ({ emails }) => {
  console.log(emails);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="emails table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align="right">Code</TableCell>
              <TableCell align="right">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emails.map((email) => (
              <TableRow
                key={email.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {email.email}
                </TableCell>
                <TableCell align="right">{email.code}</TableCell>
                <TableCell align="right">{email.createdAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EmailsTable;
