import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  QueryDocumentSnapshot,
  DocumentData,
  Timestamp,
} from "firebase/firestore";
import { firestore } from "../firebaseConfig"; // Adjust the import path as necessary
import { format } from "date-fns";

interface EmailForDisplay {
  id: string;
  email: string;
  code: string;
  createdAt: string; // Assuming this is a Firestore Timestamp object
}

interface Email {
  id: string;
  email: string;
  code: string;
  createdAt: Timestamp; // Use Firestore's Timestamp type
}

const useFetchEmails = () => {
  const [emails, setEmails] = useState<EmailForDisplay[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "emails"));
        const emailsArray: EmailForDisplay[] = querySnapshot.docs.map((doc) => {
          const data = doc.data() as Email;

          // Convert and format 'createdAt'
          const formattedCreatedAt =
            data.createdAt instanceof Timestamp
              ? format(data.createdAt.toDate(), "PPP")
              : "";

          return {
            ...data,
            createdAt: formattedCreatedAt,
            id: doc.id,
          };
        });
        setEmails(emailsArray);
      } catch (error) {
        console.error("Error fetching emails: ", error);
      }
    };

    fetchData();
  }, []);

  return emails;
};

export default useFetchEmails;
