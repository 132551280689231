import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig"; // Adjust the import path as necessary
import EmailsTable from "../crm/EmailsTable";
import useFetchEmails from "../crm/useFetchEmails";
import VideoUpload from "./VideoUpload";
import VideoList from "./VideoList";
import AssetDistributionList from "./AssetDistributionList";
import DocumentList from "./DocumentList";

const CRMWaitlist: React.FC = () => {
  const navigate = useNavigate();
  const emails = useFetchEmails();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      await signOut(auth);
      console.log("Logged out successfully");
      navigate("/login");
    } catch (error) {
      console.error("Logout failed: ", error);
      setIsLoggingOut(false); // Reset logout state on error
      // Optionally show an error message to the user
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", // Stack elements vertically
        alignItems: "center", // Center items horizontally
        justifyContent: "flex-start", // Start from the top
        height: "100%", // Full height of the viewport
        width: "100%", // Full width of the viewport
        p: 2, // Padding
        mt: { xs: "100px", sm: "120px", md: "150px", lg: "180px" }, // Adjust top margin based on screen size
        mx: "auto", // Horizontal centering
        maxWidth: "1200px", // Max width of the content
      }}
    >
      <Button
        onClick={handleLogout}
        variant="contained"
        color="primary"
        disabled={isLoggingOut}
        sx={{ mb: 2 }} // Margin bottom for the button
      >
        {isLoggingOut ? "Logging Out..." : "Logout"}
      </Button>
      <Typography variant={isMobile ? "h5" : "h1"} sx={{ mb: 2 }}>
        CRM waitlist
      </Typography>
      <EmailsTable emails={emails} />
      <VideoUpload />
      <VideoList />
      <DocumentList />
      <AssetDistributionList />
    </Box>
  );
};

export default CRMWaitlist;
