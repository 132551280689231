import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to home immediately
    navigate("/");
  }, [navigate]);

  // Optional: Render nothing or a brief loading indicator
  return null; // Or <div>Redirecting...</div> if you want to show a message
};

export default NotFoundPage;
