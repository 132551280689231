// GDPRNotice.tsx
import React from "react";
import {
  Alert,
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

type GDPRNoticeProps = {
  onClose: () => void; // Function to handle closing the notice
};

const GDPRNotice: React.FC<GDPRNoticeProps> = ({ onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: "#0d1117",
      }}
    >
      <Alert
        severity="info"
        sx={{
          color: "#fff",
          maxWidth: 1300,
          margin: "0 auto",
          backgroundColor: "#0d1117",
        }}
        action={
          <Button color="inherit" size="small" onClick={onClose}>
            OK
          </Button>
        }
      >
        <Typography variant={isMobile ? "caption" : "subtitle2"}>
          Our website does not collect or store any personal data from our
          visitors. We use cookies solely to enhance user experience and for no
          other purpose. No personal data is tracked or stored.
        </Typography>
      </Alert>
    </Box>
  );
};

export default GDPRNotice;
